import { Box } from '@mui/material';
import { Theme } from '@mui/material/styles';
// @mui
import { SxProps } from '@mui/system';
import { Image as SanityImageProps } from '@sanity/types';
import { imageProps } from '@utils/image';
import NextImage, { ImageProps } from 'next/image';

// ----------------------------------------------------------------------

export type ImageRato = '4/3' | '3/4' | '6/4' | '4/6' | '16/9' | '9/16' | '21/9' | '9/21' | '1/1';


interface Props extends Omit<ImageProps, "src"> {
  sx?: SxProps<Theme>;
  ratio?: ImageRato;
  contain?: boolean;
  src: SanityImageProps | string;
  objectPosition?: string;
}

export default function NextSanityImage({ ratio, sx, src, layout, contain = false, priority = false, sizes, ...other }: Props) {
  if (!layout) {
    layout = "fill"
  }


  if (ratio) {
    return (
      <Box
        component="span"
        sx={{
          width: 1,
          lineHeight: 0,
          display: other.width ? 'flex' : "block",
          overflow: 'hidden',
          position: 'relative',
          ...(!other.width && { pt: getRatio(ratio) }),
          ...((other.width && other.height) && { height: other.width > other.height ? other.height : getNewRatio(ratio, other.width + "px") }),
          ...sx,
        }
        }
      >
        <NextImage
          sizes={sizes}
          layout={layout}
          {...imageProps(src, true)}
          priority={priority}
          objectFit={contain ? 'contain' : 'cover'}
          {...other}
        />
      </Box >
    );
  }

  return (
    <Box
      component="span"
      position="relative"
      sx={{
        lineHeight: 0,
        display: 'block',
        overflow: 'hidden',
        '& .wrapper': { width: 1, height: 1, backgroundSize: 'cover !important' },
        ...sx,
      }}
    >
      {/* @ts-ignore */}
      <NextImage
        layout={layout}
        sizes={sizes}
        {...imageProps(src, true)}
        objectFit={contain ? 'contain' : 'cover'}
        priority={priority}
        {...other}
      />
    </Box>
  );
}

// ----------------------------------------------------------------------

function getRatio(ratio = '1/1') {
  return {
    '4/3': 'calc(100% / 4 * 3)',
    '3/4': 'calc(100% / 3 * 4)',
    '6/4': 'calc(100% / 6 * 4)',
    '4/6': 'calc(100% / 4 * 6)',
    '16/9': 'calc(100% / 16 * 9)',
    '9/16': 'calc(100% / 9 * 16)',
    '21/9': 'calc(100% / 21 * 9)',
    '9/21': 'calc(100% / 9 * 21)',
    '1/1': '100%',
  }[ratio];
}

function getNewRatio(ratio = '1/1', width: string) {
  return {
    '4/3': `calc(${width} / 4 * 3)`,
    '3/4': `calc(${width} / 3 * 4)`,
    '6/4': `calc(${width} / 6 * 4)`,
    '4/6': `calc(${width} / 4 * 6)`,
    '16/9': `calc(${width} / 16 * 9)`,
    '9/16': `calc(${width} / 9 * 16)`,
    '21/9': `calc(${width} / 21 * 9)`,
    '9/21': `calc(${width} / 9 * 21)`,
    '1/1': width,
  }[ratio];
}
