/* eslint-disable react-hooks/rules-of-hooks */
import { ImageUrlBuilder, useNextSanityImage } from '@fevernova90/next-sanity-image';
import imageUrlBuilder from '@sanity/image-url';
import { SanityImageSource } from '@sanity/image-url/lib/types/types';
import sanity from './sanity';

const myCustomImageBuilder = (
  imageUrlBuilder: ImageUrlBuilder,
  options: { width: any; originalImageDimensions: { width: number } }
) => {
  return imageUrlBuilder
    .width(Math.min(options.width || Math.min(options.originalImageDimensions.width, 900), 1920))
    .auto('format')
    .quality(80);
};

const imageBuilder = imageUrlBuilder(sanity);
const blurOptions = {
  enableBlurUp: true,
  blurUpImageWidth: 64,
  blurUpImageQuality: 30,
  blurUpAmount: 20,
};

const imageUrlFor = (source: SanityImageSource, width?: number) =>
  imageBuilder
    .width(width ? width : 900)
    .image(source)
    .auto('format');

export const imageProps = (source: SanityImageSource, omitSize = false) =>
  omitSize
    ? (({ src, blurDataURL, loader }) => ({ src, blurDataURL, loader }))(
        useNextSanityImage(sanity, source, { imageBuilder: myCustomImageBuilder, ...blurOptions })
      )
    : useNextSanityImage(sanity, source);

export default imageUrlFor;
