import { Button, Divider, Link, Typography } from '@mui/material';
import { Box, styled } from '@mui/system';
import BlockContent from '@sanity/block-content-to-react';
import NextImage from 'next/image';
import NextLink from 'next/link';
import ReactPlayer from 'react-player/vimeo';
import Routes from 'src/routes';
import { imageProps } from './image';

const StyledReactPlayer = styled(ReactPlayer)(() => ({
  position: 'absolute',
  top: 0,
  left: 0,
}));

const StyledNextImage = styled(NextImage)(() => ({
  borderRadius: 16,
}));

const PostBody = ({ content }) => (
  <BlockContent
    blocks={content}
    imageOptions={{ q: 60, width: 700 }}
    serializers={serializers}
    projectId={process.env.SANITY_STUDIO_API_PROJECT_ID}
    dataset={process.env.SANITY_STUDIO_API_DATASET}
  />
);

const MARGIN = {
  marginTop: 3,
  marginBottom: 2,
};

const overrides = {
  h1: (props) => <Typography variant="h2" sx={{ ...MARGIN }} {...props} />,
  h2: (props) => <Typography variant="h3" sx={{ ...MARGIN }} {...props} />,
  h3: (props) => <Typography variant="h4" sx={{ ...MARGIN }} {...props} />,
  normal: (props) => <Typography variant="body1" sx={{ ...MARGIN }} {...props} />,
};

const serializers = {
  types: {
    block: (props) =>
      // Check if we have an override for the “style”
      overrides[props.node.style]
        ? // if so, call the function and pass in the children, ignoring
          // the other unnecessary props
          overrides[props.node.style]({ children: props.children })
        : // otherwise, fallback to the provided default with all props
          BlockContent.defaultSerializers.types.block(props),
    video: ({ node }) => {
      const { url } = node;

      return <ResponsivePlayer url={url} />;
    },
    horizontalRule: () => {
      return <Divider />;
    },
    image: (props) => {
      const image = imageProps(props.node);
      const ratio = image.width / image.height;
      const dimensionsSet = props.node.width || props.node.height;
      return (
        <Box
          position="relative"
          width={props.node.width | 1}
          height={
            props.node.height
              ? props.node.height
              : props.node.width
              ? props.node.width / ratio
              : 320
          }
          maxHeight={320}
        >
          <StyledNextImage
            alt={props.node.alt || ''}
            objectFit={dimensionsSet ? 'contain' : 'cover'}
            layout="fill"
            {...image}
          />
        </Box>
      );
    },
  },
  marks: {
    link: ({ mark, children }) => {
      const { 0: title } = children;

      return <CustomLink link={{ ...mark, title }} />;
    },
  },
};

const ResponsivePlayer = ({ url }) => {
  return (
    <Box sx={{ position: 'relative', paddingTop: '56.25%' }}>
      <StyledReactPlayer
        width="100%"
        height="100%"
        config={{
          vimeo: {
            playerOptions: { byline: false, portrait: false, title: false },
          },
        }}
        controls
        url={url}
      />
    </Box>
  );
};

const CustomLink = ({ link, children, ...rest }) => {
  if (link.linkType == 'external') {
    return (
      <a href={link.url} target="_blank" rel="noopener noreferrer">
        {link.isButton ? (
          <Button color={link.styles?.style} variant="contained">
            {link.title || children}
          </Button>
        ) : (
          <>
          {link.title || children}
          </>
        )}
      </a>
    );
  } else {
    return (
      <NextLink
        href={Routes[link.page.type]('[slug]')}
        as={Routes[link.page.type](link.page.slug)}
        passHref
      >
        {link.isButton ? (
          <Button color={link.styles?.style} variant="contained">
            {link.title || children}
          </Button>
        ) : (
          <Link color="secondary">{link.title || children}</Link>
        )}
      </NextLink>
    );
  }
};

export default PostBody;
